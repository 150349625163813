import DeprecatedH4 from "components/deprecated/DeprecatedH4";
import CodeExample from "components/docs/code-examples/CodeExample";
import HeaderBar from "components/docs/layout/HeaderBar";
import CrossGuideLinks from "components/docs/navigation/CrossGuideLinks";
import LINKS from "components/docs/navigation/links";
import NavigationScrollTracker from "components/docs/navigation/NavigationScrollTracker";
import NavigationScrollTrackerWithAnchor from "components/docs/navigation/NavigationScrollTrackerWithAnchor";
import SmallWidthSection from "components/docs/sections/SmallWidthSection";
import UnevenTwoColumnCenteredSection from "components/docs/sections/UnevenTwoColumnCenteredSection";
import CodeBlock from "components/docs/shared-components/code-block/CodeBlock";
import DocsHelmet from "components/docs/shared-components/DocsHelmet";
import { InfoCard, WarningCard } from "components/docs/shared-components/NoticeCards";
import SectionDivider from "components/docs/shared-components/SectionDivider";
import { StaticFancyPageContainerWithTOC } from "components/docs/shared-components/StaticFancyPageContainer";
import { Link, PageProps } from "gatsby";
import React from "react";
import styled from "styled-components";

const CODE_EXAMPLES_FOLDER = "merge-writes/programmatic/templates-conditional";

/**
 * Small width section with padding
 */
const Section = styled(SmallWidthSection).attrs({ className: "mt-9 mb-9" })``;

/**
 * Small width section with smaller padding above
 */
const ShortSection = styled(SmallWidthSection).attrs({ className: "mb-9" })`
  margin-top: 0px;
`;

// Matches height of h3 + padding
const CodeExamplePadded = styled(CodeExample)`
  margin-top: 40px;
`;

// This header is specifically padded on non-mobile to match the item next to it (the highlighted block of code)
const RemoteTemplateIdHeader = styled(NavigationScrollTrackerWithAnchor)`
  margin-top: 32px !important;
  @media (min-width: 768px) {
    margin-top: 488px !important;
  }
`;

// This header is specifically padded on non-mobile to match the highlighted block of code next to it
const SecondRemoteTemplateIdHeader = styled(NavigationScrollTrackerWithAnchor)`
  margin-top: 32px !important;
  @media (min-width: 768px) {
    margin-top: 488px !important;
  }
`;

/**
 * Creates the Guide page for Nested Programmatic Writes with Meta
 */
const ProgrammaticNestedWrites = ({
  location,
}: PageProps<unknown, unknown, Record<string, unknown> | null>) => (
  <StaticFancyPageContainerWithTOC
    tableOfContents={[
      {
        text: "Overview",
        linkItems: [{ text: "Templates" }, { text: "Conditional Fields" }],
      },
      {
        text: "Example",
        linkItems: [
          {
            text: (
              <>
                First <code>/meta</code> Request
              </>
            ),
            anchorSlug: "first-meta-request",
          },
          {
            text: (
              <>
                Second <code>/meta</code> Request
              </>
            ),
            anchorSlug: "second-meta-request",
          },
        ],
      },
    ]}
  >
    <DocsHelmet
      title="Merge Writes: Templates and Conditional Fields with /meta"
      description="Learn how to use conditional fields based on user input with Merge Writes."
    />
    <SmallWidthSection>
      <NavigationScrollTracker>
        <HeaderBar
          title="Templates and Conditional Fields"
          subtitle={
            <>
              Use <code>/meta</code> to determine conditional fields based on your user’s input in
              another field
            </>
          }
        />
      </NavigationScrollTracker>
    </SmallWidthSection>

    <ShortSection>
      <NavigationScrollTrackerWithAnchor headingLevel="h3" title="Overview" />
      <NavigationScrollTrackerWithAnchor headingLevel="h4" title="Templates">
        <p>
          When writing data to third-party platforms, you will sometimes be required to select a{" "}
          <strong>template</strong> when creating a new instance of a Common Model.
        </p>
        <p>
          These templates are typically user-defined and Linked Account-specific (but not always).
        </p>
        <aside className="mb-6">
          For example, when{" "}
          <a href="https://developers.greenhouse.io/harvest.html#post-create-job">
            creating a Greenhouse job
          </a>{" "}
          via POST request directly to their API, the JSON body requires a{" "}
          <code>template_job_id</code> parameter indicating an existing job and associated settings
          used to generate the new job.
        </aside>
        <p>
          The associated <code>/meta</code> endpoint will offer clarity in its{" "}
          <code>request_schema</code> as to which templates are available for selection (see example
          further below).
        </p>
        <aside style={{ marginBottom: "48px" }}>
          Refer to our guide to{" "}
          <Link to={LINKS.MERGE_WRITES_PROGRAMMATIC_INTRO.linkTo}>
            Programmatic Writes with /meta
          </Link>{" "}
          for an introduction to using <code>/meta</code>.
        </aside>
        <InfoCard>
          <DeprecatedH4>
            <code>remote_template_id</code>
          </DeprecatedH4>
          <p>
            Typically, these templates are given a unique ID and might be referred to by varying
            names (e.g., <code>candidate_type_id</code>, <code>application_type_id</code>, etc.)
            depending on third-party platform.
          </p>
          <p>
            Merge refers to these template IDs by the standardized name{" "}
            <code>remote_template_id</code>.
          </p>
        </InfoCard>
      </NavigationScrollTrackerWithAnchor>
      <NavigationScrollTrackerWithAnchor headingLevel="h4" title="Conditional Fields">
        <p>
          Depending on template selection, there may arise <strong>conditional fields</strong> that
          require inputs.
        </p>
        <p>These conditional fields may be outside of Merge’s Common Model.</p>
        <p>
          This situation — where conditional possibilites are present (pending template selection) —
          will be flagged by the <code>has_conditional_fields</code> boolean flag in{" "}
          <code>request_schema</code>.
        </p>
        <p>
          In this situation, we suggest you make two GET requests to <code>/meta</code>:
        </p>
        <ol>
          <li>
            The first <code>/meta</code> request is to get the initial POST request schema including
            any templates and a flag indicating the existence of conditional fields.
          </li>
          <li>
            After your user has selected a template, you will make a second <code>/meta</code>{" "}
            request with the template selection as a parameter to fetch the schema of the
            conditional fields.
          </li>
        </ol>
        <p>
          After those two GET requests to <code>/meta</code>, you should have the full schema
          required to construct a valid POST request.
        </p>
      </NavigationScrollTrackerWithAnchor>
    </ShortSection>
    <SectionDivider />

    <Section>
      <NavigationScrollTrackerWithAnchor headingLevel="h3" title="Example">
        <p>
          Suppose an ATS platform requires users to specify a template when applying a candidate to
          a job.
        </p>
        <p>
          One Linked Account belonging to your end user happens to contain user-defined (and,
          therefore, Linked Account-specific) <code>Intern</code> and <code>FTE</code> application
          templates.
        </p>
        <p>
          The <code>Intern</code> application template requires the candidate’s <code>college</code>{" "}
          information, which isn’t part of Merge’s <code>Application</code> Common Model.
        </p>
        <p>Depending on your user’s template selection:</p>
        <ul>
          <li>
            Selecting <code>Intern</code> means <code>college</code> is required
          </li>
          <li>
            Selecting <code>FTE</code> means <code>college</code> is unavailable
          </li>
        </ul>
        <p>
          Furthermore, let’s assume you want to do a single POST request with an{" "}
          <code>Application</code> nested inside a <code>Candidate</code>.
        </p>
        <aside>
          Refer to our guide to{" "}
          <Link to={LINKS.MERGE_WRITES_PROGRAMMATIC_NESTED.linkTo}>
            Programmatic Nested Writes with /meta
          </Link>{" "}
          for an introduction to using <code>/meta</code> for nested writes.
        </aside>
        <p className="mt-9">You might set up your product to interact with our API as follows:</p>
        <ol>
          <li>
            You will need to make a <strong>first GET request</strong> to{" "}
            <code>/candidates/meta/post</code> to be aware of the existence of any template choices.
          </li>
          <li>
            Once your user selects a template, you will make a <strong>second GET request</strong>{" "}
            to <code>/candidates/meta/post</code> to fetch the full requirements — including any
            conditional fields — for a valid POST request to Merge.
          </li>
        </ol>
      </NavigationScrollTrackerWithAnchor>
    </Section>
    <UnevenTwoColumnCenteredSection
      columnA={
        <>
          <NavigationScrollTrackerWithAnchor
            headingLevel="h4"
            className="mt-0"
            title={
              <>
                Step 1 — First <code>/meta</code> Request
              </>
            }
            anchorSlug="first-meta-request"
          >
            <p>
              You will do your first GET request to <code>/candidates/meta/post</code> to fetch the{" "}
              <code>request_schema</code> and determine if there are any <code>Candidate</code> or
              nested Common Model templates (like <code>Application</code> in this example) and
              conditional fields to be accounted for.
            </p>
            <p>The response to this request might look like this example:</p>
          </NavigationScrollTrackerWithAnchor>

          <RemoteTemplateIdHeader
            headingLevel="h5"
            title={<code>remote_template_id</code>}
            anchorSlug="remote_template_id"
          >
            <p>
              This first <code>request_schema</code> from <code>/candidates/meta/post</code>{" "}
              declares that selecting a <code>remote_template_id</code> is a required input to nest
              an <code>Application</code> within a newly created <code>Candidate</code>.
            </p>
            <p>
              As a result, you should make a second GET request to{" "}
              <code>/candidates/meta/post</code> with the selection of your{" "}
              <code>remote_template_id</code> as a query parameter to determine full requirements
              for a valid POST request to create a new <code>Candidate</code> with a nested{" "}
              <code>Application</code>.{" "}
            </p>
            <WarningCard isVertical className="mt-16 mb-16">
              <p>
                Instead of parsing <code>anyOf</code>, we highly recommend making a second request
                to <code>/meta</code> with your user’s selection of <code>remote_template_id</code>{" "}
                to re-use any programmatic logic you may already have in place to handle basic{" "}
                <code>/meta</code> schemas.
              </p>
              <p>
                While <code>anyOf</code> may look trivial to parse in the example above, this is an
                illustrative example and may include much more complex branching in production,
                which can result in errors.
              </p>
              <aside>
                <p>
                  <code>anyOf</code> is included in <code>request_schema</code> as a JSON Schema
                  convention.
                </p>
                <p>
                  To learn more about <code>anyOf</code>, refer to the{" "}
                  <Link to={`${LINKS.MERGE_WRITES_PROGRAMMATIC_INTRO.linkTo}#request_schema`}>
                    JSON Schema specification.
                  </Link>
                </p>
              </aside>
            </WarningCard>
          </RemoteTemplateIdHeader>
          <NavigationScrollTrackerWithAnchor
            headingLevel="h5"
            title={<code>has_conditional_fields</code>}
            anchorSlug="has_conditional_fields"
          >
            <p>
              <code>request_schema</code> has several helper variables to help you programmatically
              form valid POST requests.
            </p>
            <p>
              One of those variables is the <code>has_conditional_fields</code> flag.
            </p>
            <p>
              When this boolean flag is <code>true</code>, there are POST request fields conditional
              on your user’s selection of <code>remote_template_id</code> for the{" "}
              <code>Candidate</code> or any nested Common Models (like <code>Application</code> in
              this example).
            </p>
          </NavigationScrollTrackerWithAnchor>
        </>
      }
      columnB={
        <CodeExamplePadded
          folder={CODE_EXAMPLES_FOLDER}
          codeBlockName={
            <p>
              Example <code>request_schema</code> featuring <code>Application</code> templates
              (nested in <code>Candidate</code>)
            </p>
          }
          colorScheme="light"
          hasLineNumbers={false}
          isVerticallyPadded={false}
          hideCopyButtonWithHeader
          fileBaseName="first-request-schema"
        />
      }
    />

    <Section>
      <NavigationScrollTrackerWithAnchor
        headingLevel="h4"
        title={
          <>
            Step 2 - Second <code>/meta</code> Request
          </>
        }
        anchorSlug="second-meta-request"
      >
        <p>
          In your second GET request to <code>/candidates/meta/post</code>, pass your user’s{" "}
          <code>remote_template_id</code> selection in the request as a query parameter like this:
        </p>
        {/* This is bash, but javascript lets us use the inline comments */}
        <CodeBlock
          language="javascript"
          colorScheme="highContrastDark"
          text="GET https://api.merge.dev/api/ats/v1/candidates/meta/post/* highlight-inline-start */?remote_template_id=INTERN/* highlight-inline-end */"
          hasLineNumbers={false}
          hideCopyButtonWithHeader
        />
        <aside className="mb-9">
          <DeprecatedH4>Templates with Nested Writes</DeprecatedH4>
          <p>
            In the event that <code>remote_template_id</code> pertains to a template selection made
            for a <strong>nested Common Model</strong> — such as a template for an{" "}
            <code>Application</code> nested in a <code>Candidate</code> (as part of a{" "}
            <Link to={LINKS.MERGE_WRITES_RELATED_NESTED.linkTo}>Nested Write</Link>) — you would{" "}
            <strong>prepend the nested Common Model name</strong> to the query parameter of the
            second GET request.
          </p>
          <p className="mb-6">This GET request may look like:</p>
          {/* This is bash, but javascript lets us use the inline comments */}
          <CodeBlock
            language="javascript"
            colorScheme="highContrastDark"
            text="GET .../candidates/meta/post?/* highlight-inline-start */application/* highlight-inline-end */_remote_template_id=INTERN"
            hasLineNumbers={false}
            isVerticallyPadded={false}
            hideCopyButtonWithHeader
          />
        </aside>
        <InfoCard>
          <DeprecatedH4>Important Note</DeprecatedH4>
          <p>
            In the illustrative two-step example in this guide, since the{" "}
            <code>remote_template_id</code> selection of <code>INTERN</code> pertains to an{" "}
            <code>Application</code> nested in a <code>Candidate</code>, you would prepend the
            nested Common Model name to the query parameter like in the “Templates with Nested
            Writes” box above.
          </p>
        </InfoCard>
      </NavigationScrollTrackerWithAnchor>
    </Section>

    <UnevenTwoColumnCenteredSection
      columnA={
        <>
          <p>
            The response from this second GET request to <code>/candidates/meta/post</code> would
            provide the information needed to construct a valid POST request for creating a new{" "}
            <code>Candidate</code> (with a nested <code>Application</code>) for the given Linked
            Account.
          </p>
          <SecondRemoteTemplateIdHeader
            headingLevel="h5"
            title={<code>remote_template_id</code>}
            anchorSlug="remote_template_id-second"
          >
            <p>
              Since you submitted a valid <code>remote_template_id</code> as part of your second{" "}
              <code>/meta</code> request, you will see the active template in your{" "}
              <code>request_schema</code>.
            </p>
          </SecondRemoteTemplateIdHeader>
          <NavigationScrollTrackerWithAnchor
            headingLevel="h5"
            className="mt-9"
            title={<code>linked_account_params</code>}
            anchorSlug="linked_account_params"
          >
            <p>
              With an active template, this <code>request_schema</code> now shows an updated{" "}
              <code>linked_account_params</code> object specifying that <code>college</code> is
              required for the nested <code>Application</code>.
            </p>
            <p>
              (This is in <code>linked_account_params</code> because this is a user-defined template
              and, therefore, a Linked Account-specific field.)
            </p>
            <aside className="mt-6 mb-9">
              <p>
                Refer to our guide to{" "}
                <Link to={LINKS.MERGE_WRITES_PROGRAMMATIC_INTRO.linkTo}>
                  Programmatic Writes with <code>/meta</code>
                </Link>{" "}
                for an introduction to Linked Account-specific fields and using{" "}
                <code>linked_account_params</code>.
              </p>
            </aside>
          </NavigationScrollTrackerWithAnchor>
          <NavigationScrollTrackerWithAnchor
            headingLevel="h5"
            title={<code>has_conditional_fields</code>}
            anchorSlug="has_conditional_fields"
          >
            <p>
              Note that <code>has_conditional_fields</code> is now false, signifying that all
              conditional possibilities in <code>Candidate</code> and any nested Common Models (like{" "}
              <code>Application</code> in this example) have been resolved.
            </p>
          </NavigationScrollTrackerWithAnchor>
        </>
      }
      columnB={
        <CodeExample
          folder={CODE_EXAMPLES_FOLDER}
          codeBlockName={
            <p>
              Example <code>request_schema</code> featuring <code>Application&apos;s</code>{" "}
              conditional fields
            </p>
          }
          colorScheme="light"
          hasLineNumbers={false}
          isVerticallyPadded={false}
          hideCopyButtonWithHeader
          fileBaseName="second-request-schema"
        />
      }
    />

    <UnevenTwoColumnCenteredSection
      columnA={
        <>
          <p>
            In accordance with your second <code>/meta</code> response, your final valid POST
            request might look like the following:
          </p>
        </>
      }
      columnB={
        <CodeExample
          folder={CODE_EXAMPLES_FOLDER}
          codeBlockName={<p>Example POST request body</p>}
          hasLineNumbers={false}
          isVerticallyPadded={false}
          fileBaseName="request-body"
        />
      }
    />

    <Section>
      <CrossGuideLinks location={location} usesQuaternaryLinks />
    </Section>
  </StaticFancyPageContainerWithTOC>
);

export default ProgrammaticNestedWrites;
